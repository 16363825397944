<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">学生管理</div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_search_box">
          <div class="hk_search_box_item">
            搜索：

            <!--归属人等接口有参数了再显示；买家信息等微信昵称授权后再显示
                        <el-input
                            debounce size="small" clearable
                            placeholder="归属人" disabled
                            v-model="searchName">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="hk_search_box_item"> 
                        <el-input
                            debounce size="small" clearable
                            placeholder="买家信息"
                            v-model="searchNickName">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="hk_search_box_item">-->
            <el-input debounce size="small" clearable placeholder="学生名" class="hk-ipt-number" v-model.number="searchStuName">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="hk_search_box_item">
            学习手机号：
            <el-input debounce size="small" clearable placeholder="全拼手机号或后四位" class="hk-ipt-number" v-model="searchPhone">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="hk_search_box_item">剩余额度：
            <el-input type="number" class="hk-ipt-number" debounce placeholder="请输入" clearable v-model="searchBalanceStart" size="small" style="width: 85px!important;"></el-input>
            —
            <el-input type="number" class="hk-ipt-number" debounce placeholder="请输入" clearable v-model="searchBalanceEnd" size="small" style="width: 85px!important;"></el-input>

          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchFun">搜索</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          搜索共有 <span class="hk-font-highlight">{{ totalNum }}</span> 个账号
        </div>

        <el-table :data="tableData" style="width: 100%">
          <!-- <el-table-column label="买家信息" min-width="20%">
                        <template slot-scope="scope">
                            <img :src="scope.row.headImg" alt="" style="width: 44px;height: 44px;border-radius: 50%;">
                            <span>{{scope.row.nickname}}</span>
                        </template>
                    </el-table-column> -->

          <el-table-column label="学习账号" min-width="20%">
            <template slot-scope="scope">
              <el-popover placement="top-start" width="280" trigger="hover">
                <el-form label-width="80px" size="mini">
                  <el-form-item label="姓名：">
                    <input type="text" class="hk-ipt-link-copy" :id="'j_link_copy_name_'+scope.row.id" readonly :value="scope.row.stuName">&nbsp;
                    <el-button type="text" @click.native="copyLinkFun('j_link_copy_name_'+scope.row.id)">复制
                    </el-button>
                  </el-form-item>
                  <el-form-item label="手机号：">
                    <input type="text" class="hk-ipt-link-copy" :id="'j_link_copy_phone_'+scope.row.id" readonly :value="scope.row.phone">&nbsp;
                    <el-button type="text" @click.native="copyLinkFun('j_link_copy_phone_'+scope.row.id)">复制
                    </el-button>
                  </el-form-item>
                  <el-form-item label="eeoUid：">
                    <input type="text" class="hk-ipt-link-copy" :id="'j_link_copy_eeo_'+scope.row.id" readonly :value="scope.row.eeoUid">&nbsp;
                    <el-button type="text" @click.native="copyLinkFun('j_link_copy_eeo_'+scope.row.id)">复制
                    </el-button>
                  </el-form-item>
                  <el-form-item label="StuId：">
                    <input type="text" class="hk-ipt-link-copy" :id="'j_link_copy_stuid_'+scope.row.id" readonly :value="scope.row.stuId">&nbsp;
                    <el-button type="text" @click.native="copyLinkFun('j_link_copy_stuid_'+scope.row.id)">复制
                    </el-button>
                  </el-form-item>

                </el-form>

                <div class="hk-font-highlight" slot="reference">{{ scope.row.stuName }} <br>{{scope.row.phone}}</div>

              </el-popover>

              <!-- <div>{{ scope.row.stuName }}</div>
                            <span>{{scope.row.phone}}</span> -->
            </template>
          </el-table-column>
          <el-table-column label="累计充值额度" min-width="15%">
            <template slot-scope="scope">
              <span>{{ scope.row.totalAmount /100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="当前剩余额度" min-width="15%">
            <template slot-scope="scope">
              <span>{{scope.row.balanceAmount / 100}}</span>
            </template>
          </el-table-column>
          <el-table-column label="账号创建时间" min-width="15%">
            <template slot-scope="scope">
              <span>{{comMomentFmt(scope.row.createTime)}}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" min-width="15%">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="showDrawerFun(scope.row)">查看详情</span>

            </template>
          </el-table-column>

          <div slot="empty">
            <p>没有你要找的账号哦！</p>
          </div>
        </el-table>
        <div class="hk_pagination" v-if="totalNum>0">
          <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
          </el-pagination>
          <!-- total,prev, pager, next -->
        </div>
      </div>

    </div>

    <el-drawer v-loading="drawerLoading" title="" size="50%" :with-header="false" :visible.sync="ifShowDrawerDetail" direction="rtl">
      <div class="drawer-user">
        <img :src="curStuImg" alt="" class="user-img">
        <div class="user-name">{{ curStuName }}</div>
        <div class="user-radio">
          <el-radio-group size="mini" v-model="radioOrderType">
            <el-radio-button label="1v1">卡包明细</el-radio-button>
            <el-radio-button label="EXERCISE">班课明细</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <!-- v-if="radioOrderType=='1v1'" -->
      <template v-if="radioOrderType=='EXERCISE'">
        <div class="drawer-exercise">
          <div class="exercise-item" v-if="stuCourseOrderList.length<1">
            <div class="ei-title">暂无班课订单数据</div>

          </div>
          <div class="exercise-item" v-for="(item,idx) in stuCourseOrderList" :key="idx">
            <div class="ei-title"><span class="title-tag">已开课</span>
              <div class="title-txt">{{item.courseName}} </div>
              <div class="title-zhan" @click="seeExerciseDetail(idx)" v-show="!item.lessonGetShow">展开</div>
            </div>
            <div class="ei-info">
              课程类型：<span class="hk-font-highlight">刷题课</span>&nbsp;&nbsp;&nbsp;&nbsp;
              课次：<span class="hk-font-highlight">{{item.lessonNum}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              课程有效期：<span class="hk-font-highlight">{{item.expiryTime}}</span>
            </div>
            <div class="ei-lesson" v-show="item.lessonGetShow">
              <div class="lesson-title">课上学习情况</div>
              <div class="lesson-table">
                <el-table :data="item.lessonGetList" size="mini" style="width: 100%">
                  <el-table-column label="课节" mi-width="20%">
                    <template slot-scope="scope">
                      <span>{{scope.row.className}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="授课老师" mi-width="20%">
                    <template slot-scope="scope">
                      <span>{{scope.row.teacherName}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="上课时间" mi-width="40%">
                    <template slot-scope="scope">
                      <span>{{comMomentFmt(scope.row.beginTime,'YYYY.MM.DD HH:mm')}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="上课状态" mi-width="20%">
                    <template slot-scope="scope">
                      <span> 开发中~ {{scope.row.id}} </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

          <div class="exercise-item" style="display:none;">
            <div class="ei-title"><span class="title-tag">已开课</span>
              <div class="title-txt">DEMO数据 </div>
              <div class="title-zhan">展开</div>
            </div>
            <div class="ei-info">
              课程类型：<span class="hk-font-highlight">刷题课</span>&nbsp;&nbsp;&nbsp;&nbsp;
              课次：<span class="hk-font-highlight">10</span>&nbsp;&nbsp;&nbsp;&nbsp;
              课程有效期：<span class="hk-font-highlight">10</span>
            </div>
            <div class="ei-lesson">
              <div class="lesson-title">课上学习情况</div>
              <div class="lesson-table">
                <el-table :data="stuBillList" size="mini" style="width: 100%">
                  <el-table-column label="课节" width="100px">
                    <template slot-scope="scope">
                      <span>{{scope.row.className}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="授课老师">
                    <template slot-scope="scope">
                      <span>{{scope.row.teacherName}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="上课时间">
                    <template slot-scope="scope">
                      <span>{{scope.row.beginTime}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="上课状态">
                    <template slot-scope="scope">
                      <span>{{scope.row.id}}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="drawer-order">
          订单：<el-select v-model="sltOutTradeNo" size="small" placeholder="请选择" @change="handlerChangeFun">
            <el-option :label="item.productName" :value="item.outTradeNo" :key="item.id" v-for="item in stuOrderList">{{ item.productName }}</el-option>
          </el-select>
        </div>
        <div class="drawer-table">
          <el-table :data="stuBillList" size="mini" style="width: 100%">
            <el-table-column label="课节" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.className}}</span>
              </template>
            </el-table-column>
            <el-table-column label="课时" width="80px">
              <template slot-scope="scope">
                <span>{{scope.row.classHour}}课时</span>
              </template>
            </el-table-column>
            <el-table-column label="课耗" width="100px">
              <template slot-scope="scope">
                <span>{{(scope.row.billAmount||0)/100}}</span>
              </template>
            </el-table-column>
            <el-table-column label="授课老师">
              <template slot-scope="scope">
                <span>{{scope.row.teacherName}}({{ scope.row.feePerHour/100 }}￥/课时)</span>
              </template>
            </el-table-column>
            <el-table-column label="上课时间">
              <template slot-scope="scope">
                <span>{{comMomentFmt(scope.row.beginTime,'YYYY.MM.DD HH:mm')}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </el-drawer>

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchName: '', //搜索条件--归属人
      searchNickName: '', //搜索条件--买家昵称
      searchStuName: '',//搜索条件--学生账号--学生名
      searchPhone: '', //搜索条件--学生账号--学习手机号
      searchBalanceStart: '',//搜索条件--剩余额度start
      searchBalanceEnd: '', //搜索条件--剩余额度end
      tableData: [], //员工列表中显示的数据(搜索后,只显示符合条件的员工)
      pageSize: 10, //每页10条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据


      ifShowDrawerDetail: false, //查看详情的drawer是否显示
      drawerLoading: false, //loading
      radioOrderType: '1v1',
      curStuId: '', //当前学员的
      curStuPhone: '', //当前学员的手机号
      curStuName: '', //当前学员的姓名
      curStuImg: '', //当前学员的头像
      stuOrderList: [], //当前学员的订单列表
      stuBillList: [], //课耗明细
      sltOutTradeNo: '', //搜索时，学员的outTradeNo
      stuCourseOrderList: [], //班课订单

    }
  },
  mounted() {
    this.getStuListFun()
  },
  watch: {
    ifShowDrawerDetail: {
      handler: function (val, old) {
        if (!val) { //不显示的时候，数据设为默认值
          this.radioOrderType = '1v1'
        }
      }
    }

  },
  methods: {
    //复制链接
    copyLinkFun(idEle) {
      let tmpTxt = document.getElementById(idEle).value
      console.log('复制的内容---', tmpTxt)
      navigator.clipboard.writeText(tmpTxt).then(() => {
        // console.log('复制成功');
        this.$message({
          message: '复制成功',
          type: 'success'
        })
      }).catch(err => {
        console.error('复制失败', err);
      });
    },
    searchFun() {
      this.curPage = 1 //从第一页开始
      this.tableData = [];
      this.totalNum = 0
      this.getStuListFun()

    },
    //获取学生列表
    getStuListFun() {
      let that = this;
      let param = {
        pageNum: that.curPage,
        pageSize: that.pageSize,

      };
      if (that.searchName && that.searchName.trim().length > 0) { //归属人
        param.staffName = that.searchName.trim()
      }
      if (that.searchNickName && that.searchNickName.trim().length > 0) { //买家昵称
        param.nickname = that.searchNickName.trim()
      }
      if (that.searchStuName && that.searchStuName.trim().length > 0) { //学生名
        param.stuName = that.searchStuName.trim()
      }
      if (that.searchNickName && that.searchNickName.trim().length > 0) { //买家昵称
        param.nickname = that.searchNickName.trim()
      }
      if (that.searchPhone) { // 学习手机号
        param.phone = that.searchPhone
      }
      if (that.searchBalanceStart) {//搜索条件--剩余额度start
        param.balanceAmountStart = Number(that.searchBalanceStart)
      }
      if (that.searchBalanceEnd) {//搜索条件--剩余额度end
        param.balanceAmountEnd = Number(that.searchBalanceEnd)
      }


      that.request({
        method: 'post',
        url: '/manage/student/listStudent',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.tableData = data.list || [];
            that.totalNum = Number(data.total);
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取学生列表失败。')
          console.log(error, '-----fenye 获取学生失败')
        }
      })
    },
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getStuListFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getStuListFun();
    },

    //显示drawer
    showDrawerFun(row) {
      let that = this;
      that.curStuId = row.stuId
      that.curStuPhone = row.phone
      that.curStuName = row.stuName
      that.curStuImg = row.headImg
      that.ifShowDrawerDetail = true //显示drawer

      //获取此学员的订单列表和课耗列表
      that.getOrderByStu(that.curStuPhone)
      that.getBillByStu(that.curStuId)
      that.getStuCourseFun(that.curStuId)

    },
    //根据学员手机号获取订单  卡包明细 ↓
    getOrderByStu(stuPhone) {
      let that = this;
      that.stuOrderList = []
      that.request({
        method: 'post',
        url: '/manage/1v1/listOrderByStaff',
        param: {
          phone: stuPhone
        },
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.stuOrderList = data.list || []
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取学生的订单列表失败。')
          console.log(error, '----- 获取学生订单失败')
        }
      })
    },
    //获取学生的课耗信息，点击学生的“查看详情”时，不传 outTradeNo参数
    getBillByStu(stuId, outTradeNo) {
      let that = this;
      that.drawerLoading = true //显示loading

      that.stuBillList = []
      let param = {
        stuId: stuId
      }
      if (outTradeNo) { //点击学生的“查看详情”时，不传 outTradeNo参数,表示查看所有课程的课耗
        param.outTradeNo = outTradeNo
      }
      that.request({
        method: 'post',
        url: '/manage/student/listStudentBill',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.stuBillList = data.list || []

          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取学生的课耗kehao列表失败。')
          console.log(error, '----- 获取学生课耗失败')
        }
      })
      setTimeout(() => {
        that.drawerLoading = false;
      }, 500);
    },
    //更换订单后，课耗明细的订单
    handlerChangeFun(outTradeNo) {
      console.log('select值----', this.sltOutTradeNo, '======', outTradeNo)
      this.getBillByStu(this.curStuId, outTradeNo)
    },

    //获取班课订单
    getStuCourseFun(stuId) {
      let that = this;
      that.stuOrderList = []
      that.request({
        method: 'post',
        url: '/manage/1v1/listStudentCourse',
        param: {
          pageNum: 1,
          pageSize: 50,
          stuId: stuId
        },
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.stuCourseOrderList = data.list || []
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取学生的班课订单列表失败。')
          console.log(error, '----- 获取学生班课订单失败')
        }
      })
    },

    //展开操作 
    seeExerciseDetail(idx) {
      let that = this;
      let curStuCourseInfo = that.stuCourseOrderList[idx]
      that.request({
        method: 'post',
        url: '/manage/1v1/listStudentLesson',
        param: {
          courseId: curStuCourseInfo.courseId,
          stuId: that.curStuId
        },
        success: function (result) {
          let list = result.result || [];

          that.$set(that.stuCourseOrderList[idx], 'lessonGetList', list)
          that.$set(that.stuCourseOrderList[idx], 'lessonGetShow', true)
          console.log(that.stuCourseOrderList[idx], '--------that.stuCourseOrderList[idx]')

        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取课上情况失败')
          console.log(error, '----- 获取课上情况失败')
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.fee-box {
  .fee-item {
    display: flex;
    margin-bottom: 10px;
    .fee-add {
      border-radius: 4px;
      border: 1px dashed #e0e0e0;
      text-align: center;
      color: #e0e0e0;
      position: relative;
      cursor: pointer;
      flex: 1;
      &:hover {
        color: #f08d00;
        border-color: #f9d199;
      }
    }
  }
}
.drawer-user {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  padding: 12px 16px 20px;
  .user-img {
    width: 56px;
    height: 56px;
    border-radius: 88px;
    border: 1px solid #e0e0e0;
  }
  .user-name {
    padding-left: 12px;
  }
  .user-radio {
    flex: 1;
    text-align: right;
  }
}
.drawer-order {
  padding: 0 24px 20px;
}
.drawer-table {
  padding: 0 24px;
}

.drawer-exercise {
  padding: 0 24px 8px;
  .exercise-item {
    border-radius: 6px;
    border: 1px solid #fbd8d8;
    padding: 16px 0;
    margin-bottom: 16px;
    .ei-title {
      padding: 0 16px 8px;
      display: flex;
      align-items: center;
      .title-tag {
        border-radius: 2px;
        border: 1px solid #f08d00;
        font-size: 14px;
        color: #f08d00;
        line-height: 14px;
        padding: 4px;
      }
      .title-txt {
        font-size: 20px;
        color: #262626;
        line-height: 28px;
        flex: 1;
        padding-left: 8px;
      }
      .title-zhan {
        font-weight: 600;
        font-size: 14px;
        color: #f08d00;
        line-height: 22px;
        cursor: pointer;
      }
    }
    .ei-info {
      padding: 0 16px 12px;
      border-bottom: 1px solid #ccc;
      font-weight: 400;
      font-size: 14px;
      color: #111111;
      line-height: 22px;
    }
    .ei-lesson {
      padding: 12px 16px 0;
      .lesson-title {
        font-weight: 500;
        font-size: 16px;
        color: #111111;
        line-height: 22px;
        padding-bottom: 8px;
      }
      .lesson-table {
        width: 100%;
      }
    }
  }
}
</style>